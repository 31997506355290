<template>
    <div class="mt-3">
        <div class="pageHeader">{{ this.rowTitle.header }}</div>
        <div class="pageDesc">{{ this.rowTitle.description }}</div>
    </div>
    <div v-if="!this.device">
        <div class="headerContainer">
            <div>
                <va-input v-if="rowApi!='wallet_balance'" class="flex md12 pr-3" v-model.trim="search_term" type="text" placeholder="Search" />
                <va-select
                v-if="(this.$cookies.get('role') === 'ADMIN') && (this.rowApi == 'bundle' || this.rowApi=='channel')"
                class="flex md6"
                v-model="provider_filter"
                text-by="name"
                clearable
                clearable-icon="cancel"
                searchable
                :options="providerList"
                placeholder="Select Provider"
                />
                <va-select
                v-if="(this.$cookies.get('org_type') === 'ADMIN' || this.$cookies.get('org_type') === 'PROVIDER' || this.$cookies.get('org_type') === 'PRIMARY') && (this.rowApi == 'subscription' || this.rowApi == 'app_settings' || this.rowApi == 'wallet' || this.rowApi == 'wallet_balance')"
                :class="this.rowApi == 'wallet_balance'? 'md12':'flex md6'"
                v-model="org_filter"
                text-by="org_name"
                clearable
                clearable-icon="cancel"
                searchable
                :options="organizationLists"
                placeholder="Select Organization"
                />
                <va-select
                v-if="this.rowApi=='qr_transaction' && this.privilageView['org_filter']"
                class="flex md6"
                v-model="org_filter"
                text-by="org_name"
                clearable
                clearable-icon="cancel"
                searchable
                :options="organizationLists"
                placeholder="Select Organization"
                />
                <va-select
                v-if="(this.$cookies.get('role') === 'ADMIN') && this.rowApi == 'cloud_tv'"
                class="flex md6"
                v-model="org_filter"
                text-by="org_name"
                clearable
                clearable-icon="cancel"
                searchable
                :options="organizationLists"
                placeholder="Select Organization"
                />
                <va-select
                    v-if="(this.$cookies.get('role') === 'ADMIN') && this.rowApi == 'inventory'"
                    class="flex md6 pr-3"
                    v-model="inventory_org_filter"
                    text-by="org_name"
                    clearable
                    clearable-icon="cancel"
                    searchable
                    :options="organizationLists"
                    placeholder="Select Organization"
                />
            </div>
            <div>
                <va-button-dropdown
                    color="#0096FC"
                    v-if="(this.$cookies.get('role') === 'ADMIN') && this.rowApi == 'inventory'"
                    label="Inventory Operations"
                    :disabled="operation_flag"
                >
                <div class="pa-1 dropdown_option">
                    <a v-for="button in operationLists" key="button" class="inventory-operation"  @click.prevent="triggerOperations(button)">
                        {{ button}}
                    </a>
                </div>
            </va-button-dropdown>
            </div>
            <div class="flex md12" v-if=tabFlag>
                <va-tabs  v-model="tabValue" color="secondary">
                  <template #tabs>
                    <va-tab v-for="tab in this.tabContent" :key="tab" color="#f1711a" :name="tab">
                      {{ tab }}
                    </va-tab>
                  </template>
                </va-tabs>
            </div>
            <div v-if="this.rowApi != 'inventory' && (rowAdd || this.rowAddTab || this.rowExport)">
                <va-button
                    v-if="(this.rowAdd || this.rowAddTab) && this.rowApi != 'app_settings'"
                    class="createBtn"
                    color="#0096FC"
                    @click.prevent="trigger('Create', null)">
                    <i class="fas fa-plus" style=" margin-right:10px;"></i>
                    Add
                </va-button>
                <div v-if="this.rowApi === 'state'" style="margin-left:6rem;margin-top: -2.2rem;">
                <va-button
                    v-if="this.rowExport"
                    class="createBtn"
                    color="#0096FC"
                    @click="exportFile()"
                >
                    Export CSV
                </va-button>
            </div>
            <div v-if="this.privilageView['wallet_export']">        
              <div v-if="this.rowApi === 'wallet'" style="margin-left:6rem;margin-top: -2.2rem;">
                <va-button
                    v-if="this.rowExport"
                    class="createBtn"
                    color="#0096FC"
                    @click="walletexportFile()"
                >
                    Export CSV
                </va-button>
            </div>
            </div>
              <div v-if="this.privilageView['bundles_export']">        
              <div v-if="this.rowApi === 'bundle'" style="margin-left:6rem;margin-top: -2.2rem;">
                <va-button
                    v-if="this.rowExport"
                    class="createBtn"
                    color="#0096FC"
                    @click="bundleexportFile()"
                >
                    Export CSV
                </va-button>
            </div>
            </div>
             <div v-if="this.rowApi === 'channel'">
                <va-button
                    v-if="this.rowExport"
                    class="createBtn"
                    color="#0096FC"
                    @click="exportFile()"
                >
                    Export CSV
                </va-button>
            </div>
              <div v-if="rowApi === 'wallet'">
                    <VaModal v-model="showModal" ok-text="OK" @click="cleardata()">
                      <template #header>
                        <h3 class="va-h3">Transaction Details</h3>
                      </template>
                      <template #default>
                        <div v-if="showData">        
                        <p><b>Bundle Name: </b><span>{{ bundle_name  }}</span></p><br>
                        <p><b>Subscription Name: </b><span>{{ subscription_name }}</span></p><br>
                        <p><b>Mobile Number: </b><span>{{ mobile_number }}</span></p><br>
                        <p><b>MAC Address: </b><span>{{ mac_address }}</span></p><br>
                        <p><b>Serial Number: </b><span>{{ stb_no }}</span></p><br>
                        <p><b>Package Name: </b><span>{{ bundle_name }}</span></p><br>
                        <p class="mb-3"><b>Package Name: </b></p>
                        <p style="width: 280px; height:200px; overflow: scroll;">
                            <p v-for="pack in package_name" >{{ pack }}</p>
                        </p><br>
                        </div>
                        <div v-else>
                            <p>Empty data</p>
                        </div>
                      </template>
                    </VaModal>
                  </div>
            </div>
        </div>
        <va-button 
            v-if="(this.$cookies.get('role') === 'ADMIN') && this.rowApi == 'inventory'"
                style="margin-top: 12px;"
                size="small"
                @click.prevent = "selectDeselect()"
        >{{ select_type}}
        </va-button>
        <div v-if="this.visibleGrid">
            <va-data-table
                class="dataTable mt-4"
                :items="contentList"
                :columns="rowHeader"
                wrapper-size="380"
                item-size="46"
                per-page="10"
                sticky-header
            > 
            <template #cell(org_name)="{ row }" v-if="this.rowApi == 'org'">
                <span @click="change(row.rowData)" style="color:#1193F4;cursor: pointer; font-weight: 500;">{{row.rowData['org_name'] }}</span>
            </template>
        <div>{{ rowApi }}</div>
           <template #cell(url)="{ row }" v-if="this.rowApi === 'invoice'">
             <a :href="row.rowData['url']" @click="openURL(row.rowData['url'])" style="color: #1AA3E9; cursor: pointer; font-weight: 500;">
                 {{ row.rowData['url'] }}
              </a>
            </template> 
            <template #cell(bundle_image)="{row}" v-if="this.rowApi === 'wallet'">
                <img
                        src="@/assets/GridBundle.png"
                        height="18"
                         @click="openModal(row.rowData)"
                        class="cursorPointer"
                    />
                
            </template>
            <template #cell(bundle)="{ row }" v-if="this.rowApi === 'bundle'">
                <span @click="viewBundle(row.rowData)" style="color: #0073E6; cursor: pointer;"
                    onmouseover="this.style.textDecoration='underline';"
                    onmouseout="this.style.textDecoration='none';">
                    View
                </span>
            </template>
            <template #cell(status)="{row}" v-if="this.rowApi === 'qr_transaction'">
                <div class="flex items-center">
                    <div 
                        style="color: red; font-weight: bold;"
                        v-if="row.rowData.status === 'pending'"
                        class="mr-3 mb-2" 
                        >Pending
                    </div>
                    <div 
                        style="color: green; font-weight: bold ;" 
                        v-if="row.rowData.status === 'completed'"
                        class="mr-3 mb-2" 
                        >Completed
                    </div>
                </div>
            </template>
            <template #cell(recheck)="{row}" v-if="this.rowApi==='qr_transaction'">
                <VaButton
                    v-if="row.rowData.status === 'pending'"
                    class="mr-6 mb-2"
                    :loading="row.rowData.loadingStatus"
                    size="small"
                    @click="trigger('payment_status_check',row.rowData)"
                    >Recheck
                </VaButton>
            </template>
            <template #cell(url)="{ row }" v-else>
              <span>{{ row.rowData['url'] }}</span>
            </template>
    
                <template #cell(checkbox)="{ row }">
                    <va-checkbox
                        v-if="(row.rowData.status==='Fresh' && !row.rowData.org_id) || inventory_org_filter"
                        v-model="row.rowData.checkbox_value"
                        :@input= "checkboxFilter()" 
                    />
                </template>
                <template #cell(image)="{ row }">
                    <img
                        :src="`${assetHost}/${row.rowData.image}?timestamp=${row.rowData.timestamp}`"
                        height="30"
                    />
                </template>
                <template #cell(logo)="{ row }">
                    <img
                        :src="`${assetHost}/${row.rowData.logo}?timestamp=${row.rowData.timestamp}`"
                        height="30"
                    />
                </template>
                <template #cell(image_url)="{ row }">
                    <img
                        :src="`${assetHost}/${row.rowData.image_url}?timestamp=${row.rowData.timestamp}`"
                        height="30"
                    />
                </template>
               <template #cell(approve)="{ row }">
                    <div class="flex items-center">
                        <va-button icon="" color="success"
                        v-if="row.rowData.status === 'Pending' && row.rowData.payment_method !='Online' &&  this.privilageView['approve_wallet'] &&  row.rowData.payment_type !='Debit' && (OrgId != row.rowData.org_id)"
                            class="mr-3 mb-2" textColor="#000000"
                            @click="trigger('Approved', row.rowData.transaction_id ? row.rowData.transaction_id : row.rowData.invoice_id)">Approve
                        </va-button>

                        <va-button icon="" color="danger" 
                        v-if="(row.rowData.status === 'Pending' && row.rowApi !== 'invoice')&& row.rowData.payment_method !='Online' && this.privilageView['decline_wallet']  &&  row.rowData.payment_type !='Debit'  && (OrgId != row.rowData.org_id)" 
                            class="mr-3 mb-2" textColor="#000000"
                            @click="trigger('Declined', row.rowData.transaction_id, row.rowData.invoice_id)">Decline
                        </va-button>
                    </div>
                </template>
                <div></div>

                <template #cell(edit)="{ row }">
                    <img
                        src="@/assets/editicon.png"
                        @click.prevent="trigger('Update',row.rowData)"
                        height="18"
                        class="cursorPointer"
                    />
                </template>
                <template v-if="rowApi==='channel'" #cell(editEpg)="{ row }">
                    <img 
                        v-if="!row.rowData.showEdit"
                        src="@/assets/editicon.png"
                        @click.prevent="allowEdit(row.rowData)"
                        height="18"
                        class="cursorPointer"
                        style="cursor: pointer;"
                    />
                </template>
                <template v-if="rowApi==='channel'" #cell(epg_channel_name)="{ row }">
                    <va-input  
                        v-model="row.rowData.epg_channel_name" 
                        :disabled="!row.rowData.allowEdit"
                        type="text" 
                        :error="row.rowData.epgNameError"
                        placeholder="EPG channel name" />
                </template>
                <template  #cell(buttons)="{ row }" >
                    <div class="flex" v-if="row.rowData.buttons">
                        <VaButton
                            border-color="primary"
                            round
                            class="mr-2"
                            preset="secondary"
                            size="small"
                            style="z-index:0"
                            @click.prevent="cancelChanges(row.rowData)"
                        >
                        Cancel
                        </VaButton>
                        <VaButton
                            preset="primary"
                            icon-color="#ffffff50"
                            round
                            size="small"
                            border-color="primary"
                            style="z-index:0"
                            @click.prevent="saveChanges(row.rowData)"
                        >
                        Save
                        </VaButton>
                        </div>
                </template>
                <template #cell(export)="{ row }" v-if="rowApi=='bundle'">
                    <img src="@/assets/export.png" 
                        title="Export channels!" 
                        @click.prevent="trigger('bundle_export',row.rowData)"
                        style="width: 20px; height: 20px; cursor: pointer;" />
                </template>
                <template #cell(view_inventory)="{ row }">
                    <va-popover style="background-color: white;" placement="bottom-right" trigger="click">
                        <i class="fa-solid fa-ellipsis fa-lg" style="color: #B9B9B9; cursor: pointer;"></i>  
                        <template #icon>
                            <button class="iconbutton" @click="trigger('View', row.rowData)">
                                <span class="ml-3">View</span>
                            </button><br><br>
                            <button class="iconbutton" @click="trigger('Assign Org', [row.rowData])">
                                <span class="ml-3">Assign Org</span>
                            </button><br><br>
                            <button class="iconbutton" @click="trigger('Assign Version', [row.rowData])">
                                <span class="ml-3">Assign Version</span>
                            </button><br><br>
                            <button class="iconbutton" @click="trigger('Run Commands', [row.rowData])">
                                <span class="ml-3">Run Commands</span>
                            </button><br><br>
                            <button class="iconbutton" @click="trigger('Trigger OTA', [row.rowData])">
                                <span class="ml-3">Trigger OTA</span>
                            </button><br><br>
                        </template>
                    </va-popover>
                </template>
                <template #cell(delete)="{ row }">
                    <img
                        src="@/assets/deleteicon.png"
                        @click.prevent="deleteCall(row.rowData)"
                        height="18"
                        class="cursorPointer"
                    />
                </template>
                <template #cell(activationDate)="{ row }"><span>{{ new Date(row.rowData.activation_date).toLocaleString() }}</span></template>
                <template #cell(expiryDate)="{ row }"><span>{{ new Date(row.rowData.expiry_date).toLocaleString() }}</span></template>
                <template  #cell(bundlepopup)="{ row }" >
                    <img src="@/assets/GridBundle.png"
                        @click="trigger('bundle' ,row.rowData)"
                        style="width: 20px; height: 20px; margin-top: 2px; cursor: pointer;"
                    />
                </template>
                <template #cell(show_subscribed_channels)="{ row }">
                    <img v-if="row.rowData.show_subscribed_channels === true" src="@/assets/status/active.png" height="22"
                    width="80" />
                    <img v-else src="@/assets/status/inactive.png" height="22" width="80" />
                </template>
                <template #cell(show_cdn_status)="{ row }">
                    <div style="text-align: center">
                        <span v-if="row.rowData.cdn_current_status == 'Active'" class="green-badge"></span>
                        <span v-else class="red-badge"></span>
                    </div>
                </template>
                <template #cell(stats)="{ row }">
                    <div style="text-align: center" v-if="row.rowData.cdn_current_status == 'Active'">
                        <i class="fa-solid fa-eye row-clipboard" @click="copyToClipboard('https://ea.bluedge.in:'+row.rowData.ui_port+'/app.html?pass_key='+row.rowData.cdn_id, 'public')"></i>
                    </div>
                </template>
                <template #cell(cdn_pubic_link)="{ row }">
                    <div style="text-align: center" v-if="row.rowData.cdn_current_status == 'Active'">
                        <i class="fa-solid fa-eye row-clipboard" @click="copyToClipboard('https://ea.bluedge.in:'+row.rowData.ui_port, 'public')"></i>
                    </div>
                </template>
                <template #cell(cdn_private_link)="{ row }">
                    <div style="text-align: center" v-if="row.rowData.cdn_current_status == 'Active'">
                        <i class="fa-solid fa-paste row-clipboard" @click="copyToClipboard('https://'+row.rowData.sub_domain+'.bluedge.in/app.html?pass_key='+row.rowData.cdn_id, 'private')"></i>
                    </div>
                </template>
            </va-data-table>
            <div class="row container mt-3 mb-2">
                <div class="pageInput">
                    <span class="mt-1 pl-2 pr-2">Go to the page</span>
                    <input ref="directPageInput" v-model.trim="direct_page" @input="directPage(10, this.direct_page)" />
                </div>
                <va-pagination
                    v-model="page_value"
                    :visible-pages="7"
                    :total="rowTotal"
                    :direction-links="false"
                    :page-size="10"
                    boundary-numbers
                    color="#888888"
                    size="small"
                    @click="getPage()"
                />
            </div>
            <div v-if="this.rowApi === 'bundle'">
                <VueSidePanel class="sidePanel" v-model="showBundle" hide-close-btn width="500px" :panel-duration="700"
                    transition-name="slide-right" no-close>
                    <div class="row">
                        <div class="cardTitle"> Bundle Name : <span>{{ view_bundle_name }}</span></div>
                        <va-icon name="close" class="closeBar" size="small" @click="closeform()"></va-icon>
                    </div>
                    <div style="margin: 20px 35px;">
                        <div class="mt-3"><b>Channels </b></div>
                        <p style="margin-top : 7px;" v-for="(channel, index) in channelList"> {{ `${index + 1}. ${channel.channel_name}` }}</p>
                    </div>
                </VueSidePanel>
            </div>
        </div>

    </div>
    <div v-else>
        <br>
        <div class="container">
            <div>
                <va-button-dropdown color="#3C3C3C" label="Create Subscription" v-if="this.rowAdd && this.rowApi == 'subscription' ">
                    <div class="pa-1 dropdown_option">
                        <a class="file-download" @click="trigger('single', {device: this.device})">Single Subscription</a>
                        <!-- <a class="file-download" @click="trigger('bulk', null)">Bulk Subscription</a> -->
                    </div>
                </va-button-dropdown>
                <va-button
                    v-if="(this.rowAdd || rowAddTab) && this.rowApi != 'subscription' && this.rowApi !='inventory' && this.rowApi != 'app_settings'"
                    class="ml-3 createBtn"
                    color="#0096FC"
                    @click="trigger('Create', null)">
                    <i class="fas fa-plus" style=" margin-right:10px;"></i>
                    Add
                </va-button>
                <div v-if="this.rowApi === 'channel'">
                <va-button
                    v-if="this.rowExport"
                    class="createBtn"
                    color="#0096FC"
                    @click="exportFile()"
                >
                    Export CSV
                </va-button>
            </div>
            </div>
            <div class="mt-4" v-if="this.visibleGrid || (this.$cookies.get('role') === 'ADMIN')">
                <i class="fa fa-angle-left pageArrow" @click.prevent="pagination('previous')"></i>
                <input v-model="page_value" class="paginationInput" @input="directPagination(page_value)"/>
                <i class="fa fa-angle-right pageArrow" @click.prevent="pagination('next')"></i>
            </div>
        </div><br><br>
        <va-input v-if="this.rowApi != 'app_settings'" class="searchBarMobile" v-model.trim="search_term" type="text" placeholder="Search" />
        <va-select
            v-if="(this.$cookies.get('role') === 'ADMIN') && (this.rowApi == 'app_settings')"
            v-model="org_filter" text-by="org_name" clearable clearable-icon="cancel" class="searchBarMobile"
            searchable :options="organizationLists" placeholder="Select Organization"
        />
        <div class="flex md12" v-if=tabFlag>
            <va-tabs v-model="tabValue" color="secondary"  @click.prevent="triggerTabs(tabValue)">
                <template #tabs>
                    <va-tab v-for="tab in this.tabContent" :key="tab" color="#f1711a" :name="tab">
                      {{ tab }}
                    </va-tab>
                </template>
            </va-tabs>
        </div>
        <div class="pt-4 overFlowMobile gradientMobile" :style="overFlowViewMobile">
            <div v-for="item in contentList">
                <div class="mobileOverFlowGridM">
                    <div class="container" style="padding-top: 8px;" @click.prevent="item.close = !item.close">
                        <div class="row">
                            <div style="margin-right:10px">
                                <img  v-if="imageFlagMobile" :src="`${assetHost}/${item.image || item.image_url }?timestamp=${item.timestamp}`" height="30" width="40" />
                            </div>
                            <div>
                                <div v-if="this.rowApi!='org'" class="ellipseM" style="width: 200px;">{{ item[this.templateValue.header] }}</div>
                                <div v-if="this.rowApi=='org'" @click="change(item)" style="color:#1193F4;cursor: pointer; font-weight: 500; width:200px;" class="ellipseM">{{ item[this.templateValue.header] }}</div>
                                <div style="padding-top: 6px; font-size: 11px;">{{ item[this.templateValue.description] }}</div>
                            </div>
                            <div style="margin-left: auto;margin-top: 10px;">
                                <img  
                                    v-if="updateFlagMobile"
                                    src="@/assets/editicon.png"
                                    @click.prevent="trigger('Update',item)"
                                    height="16"
                                    class="mr-5"
                                />
                                <img
                                v-if="deleteFlagMobile"
                                    src="@/assets/deleteicon.png"                                    
                                    @click.prevent="rowApi=='subscription'? trigger('Delete',item) :deleteCall(item)"
                                    height="16"
                                    class="ml-3"
                                />
                                <va-popover v-if='viewInventoryFlagMobile' placement="bottom-right" trigger="click">
                                    <i class="fa-solid fa-ellipsis fa-lg" style="color: #B9B9B9; cursor: pointer;"></i>  
                                    <template #icon>
                                        <button class="iconbutton" @click="trigger('View', item)">
                                            <span class="ml-3">View</span>
                                        </button><br><br>
                                        <button class="iconbutton" @click="trigger('Assign Org', [item])">
                                            <span class="ml-3">Assign Org</span>
                                        </button><br><br>
                                        <button class="iconbutton" @click="trigger('Assign Version', [item])">
                                            <span class="ml-3">Assign Version</span>
                                        </button><br><br>
                                        <button class="iconbutton" @click="trigger('Run Commands', [item])">
                                            <span class="ml-3">Run Commands</span>
                                        </button><br><br>
                                        <button class="iconbutton" @click="trigger('Trigger OTA', [item])">
                                            <span class="ml-3">Trigger OTA</span>
                                        </button><br><br>
                                    </template>
                                </va-popover>                                
                            </div><br>
                        </div>
                        <!-- <div style="margin-top: 13px;">
                            <i :class="{'fa fa-angle-down': item.close === false, 'fa fa-angle-up': item.close !== false}"></i>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <va-delete
        v-if="showDeleteModal"
        :deleteMessge="deleteMsg"
        :deleteApi="rowApi"
        :deleteId="deleteData"
        @response-event="handleResponse"
    />
</template>

<script>
    import { exportToFile } from '@/module/exportFile';
    import { searchSplit } from '@/module/searchSplit';
    import mobileGrid from '@/module/templateKey.json';
    import config from '@/router/config';
    export default{
        props:{
            rowHeader  : Array,
            rowAdd     : Boolean,
            rowApi     : String,
            rowTitle   : Object,
            rowExportData : Object,
            rowExport  : Boolean,
            tabContent : Array,
            pageContentData: Array,
            defaultTab : String,
            visibleGrid : Boolean
        },
        data(){
            return{
                tabFlag: (this.tabContent && this.tabContent.length > 0) ? true : false,
                tabValue: this.defaultTab,
                device: this.$globalDevice == 'mobile' ? true : false,
                page_value: 1,
                direct_page: '',
                search_term: null,
                templateValue: mobileGrid[this.rowApi],
                overFlowViewMobile: `height: ${this.$Height - 245}px;`,
                deleteFlagMobile: false,
                updateFlagMobile: false,
                rowBody: [],
                bundle_name : '',
                rowTotal: '',
                noEntriesFlag: false,
                showDeleteModal: false,
                deleteMsg: '',
                deleteData: '',
                showData: false,
                timestamp: '',
                assetHost: config.asset_host,
                imageFlagMobile: false,
                viewInventoryFlagMobile : false,
                org_filter: '',
                organizationLists: [],
                operation_type: '',
                inventory_org_filter: '',
                showModal: false,
                select_type: "Select All",
                operation_flag: true,
                operationLists : ['Assign Org'],
                parameter: null,
                rowAddTab:false,
                providerList:[],
                provider_filter: '',
                privilageView: JSON.parse(localStorage.getItem('privilages')),
                roleView: this.$cookies.get('role'),
                OrgId : this.$cookies.get('org_id'),
                view_bundle_name: '',
                channelList:[],
                showBundle : false,
            }
        },
        created(){
            if(this.device) this.showUpdateMobile();
            if(this.rowApi == 'bundle' || this.rowApi=='channel') this.getAllProviders();
            this.getOrg();
        },
        watch:{
            search_term(){
                // if(this.search_term == '' && !this.org_filter) {
                //     if(this.rowApi == 'bundle' && this.$cookies.get('org_type') == 'PROVIDER') {
                //         this.getAll(10, 0, `?provider_id=${this.$cookies.get('provider_id')}`)
                //     }else{
                //         this.getAll(10, 0);
                //     }
                //     this.page_value = 1;
                // }else{
                //     this.page_value = 1;
                //     searchSplit(this.search_term, this.rowApi, null, 10, this.page_value).then(response => {
                //         this.rowBody = response.data;
                //         this.rowTotal = response.count;
                //     });
                // };


                  if(this.search_term == '' && !this.org_filter) {
                    if(this.rowApi == 'bundle' && this.$cookies.get('org_type') == 'PROVIDER') {
                        this.getAll(10, 0, `?provider_id=${this.$cookies.get('provider_id')}`)
                    }else{
                        this.getAll(10, 0);
                    }
                    this.page_value = 1;
                }else{
                    searchSplit(this.search_term, this.rowApi).then(response => {
                        this.rowBody = response;
                    });
                };
                
            },
            org_filter() {
                if (this.org_filter == '') {
                    this.search_term = '';
                    this.getAll(10, 0, null);
                } else {
                    // this.search_term = '';
                    this.getAll(10, 0, `?org_id=${this.org_filter.org_id}`);
                }
            },
            provider_filter() {
                if (this.provider_filter == '') {
                    this.search_term = '';
                    this.getAll(10, 0);
                } else {
                    this.getAll(10, 0, `?provider_id=${this.provider_filter.provider_id}`);
                    this.search_term = '';
                }
            },
            tabValue () {
                this.pageContentData.map(items => {
                    if(items.tab === this.tabValue) {
                        this.parameter = items.params
                       this.rowAddTab = items.rowAdd;
                        this.updateFlagMobile = (this.defaultTab == items.tab) ? false : true
                        this.deleteFlagMobile = (this.defaultTab == items.tab) ? false : true
                    }
                })
                this.getAll(10, 0, this.parameter);
            },
            inventory_org_filter(){
                this.select_type = 'Select All';
                if(this.inventory_org_filter){
                    this.rowBody = this.rowBody.filter(data=> data.org_id == this.inventory_org_filter.org_id)
                    this.operationLists = ['Assign Org','Assign Version','Run Commands','Trigger OTA'];
                } else {
                    this.operationLists = ['Assign Org']
                    this.getAll(10, 0);
                }
            }
        },
        computed:{
            contentList(){
                return this.rowBody;
            }
        },
        methods: {
            viewBundle(data) {
                data.name = data.name || data.bundle.name
                this.axios.get(`${config.host}bundle/export-content/${data.bundle_id}`).then(resp => {
                    if(resp) {
                        this.view_bundle_name = data.name
                        this.channelList = resp.data
                        this.showBundle = true
                    }
                })  
            },
            closeform(){
                this.showBundle = false
                this.view_bundle_name = ''
            },
             openModal(data) {
                   this.axios.get(config.host + 'wallet/' + data.transaction_id).then(response => {
                    this.bundle_name = response.data['bundle_name'];
                    this.subscription_name = response.data['subscriber_name'];
                    this.mac_address = response.data['mac_address'];
                    this.stb_no = response.data['stb_no'];
                    this.mobile_number = response.data['mobile'];
                    this.package_name = response.data['package_name'];
                   })
               this.showModal = true;
           },
            allowEdit(data){
                data.allowEdit=true
                data.buttons=true
                data.showEdit=true
            },
            cancelChanges(data){
                data.allowEdit=false
                data.buttons=false
                data.showEdit=false
                data.epg_channel_name=data.epg_channel_name?data.epg_channel_name:''
                data.epgNameError=false
            },
            saveChanges(data){
                data.epgNameError=!data.epg_channel_name?true:false
                if(data.epgNameError) return
                var epg_channel_name=data.epg_channel_name
                const loader = this.$loading.show({ width: 40, height: 40 });
                this.axios.put(config.host+'channel/'+data.channel_id,{epg_channel_name}).then(response=>{
                    loader.hide();
                    this.cancelChanges(data)
                    this.getAll(10,0)
                    this.$toast.success(response.data);
                },err=>{
                    loader.hide();
                    this.$toast.error(err.response.data);
                })

            },
            cleardata() {
                this.showData = false;
                this.bundle_name = '';
                this.subscription_name = '';
                this.mobile_number = '';
                this.package_name = [];
            },
           openModal(data) {
                this.axios.get(config.host + 'wallet/' + data.transaction_id)
                    .then(response => {
                        if(response.data.msg) {
                            this.showData = false
                        } else {
                            this.showData = true
                            this.bundle_name = response.data['bundle_name'];
                            this.subscription_name = response.data['subscriber_name'];
                            this.mobile_number = response.data['mobile'];
                            this.package_name = response.data['package_name'].map((item, index) => {
                                return `${index + 1}. ${item.name}`;
                            }).join(", ").split(",");
                        }
                    })
                this.showModal = true;
            },

            change(data) {
                var parameter = { 'id': data.org_id };
                this.$cookies.set('global_org_id', data.org_id);
                this.$cookies.set('global_org_type', data.org_type);
                this.$cookies.set('global_billing_mode', data.billing_mode);
                this.$cookies.set('org_provider_id',data.provider_id);
                this.$router.push({ name: 'Operator Settings', params: parameter });
            },
             openURL(url) {
               window.open(url, '_self');
            },
            async getAll(limit, offset, parameter) {
                //var offset = this.page_value
                var num = (offset == 0) ? offset + 1 : offset;
                this.timestamp = new Date().getTime();
                if(this.org_filter.org_id) {
                    parameter = `?org_id=${this.org_filter.org_id}`
                }
                const api = parameter ? `${this.rowApi}/${limit}/${offset}/${parameter}` : `${this.rowApi}/${limit}/${offset}`;
                const arrayData = await this.$get(api, this.$loading, this.$toast)
                this.rowBody = arrayData.data.map(res=>{
                    res.id = num++ +'.';
                    res.createdAt = this.formatDateTime(res.createdAt)
                    res.updatedAt = this.formatDateTime(res.updatedAt)
                    return res;
                });
                this.noEntriesFlag = (this.rowBody.length < 10) ? true : false;
                this.rowTotal = arrayData.count;
                this.inventory_org_filter = '';
            },
            formatDateTime(dateTimeString) {
                return new Date(dateTimeString)
                    .toLocaleString('en-US', {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: '2-digit',
                        hour12: true,
                    });
               },

              exportFile(type) {
              const loader = this.$loading.show({ width: 40, height: 40 });
              let url = config.host + `${this.rowApi}/?getAllData=` + true;
              this.axios.get(url).then(response => {
                  loader.hide();
                  exportToFile(response.data, this.rowExportData, `Total ${this.rowApi} Lists`, 'CSV');
                });
            },

            walletexportFile() {
                const loader=this.$loading.show({width:40,height:40});
                this.axios.get(config.host+'wallet/').then(response => {
                    loader.hide()
                    exportToFile(response.data, this.rowExportData, 'Total wallet Lists', 'CSV');

                })
            },

             bundleexportFile() {
                const loader=this.$loading.show({width:40,height:40});
                this.axios.get(config.host+'bundle/').then(response => {
                    loader.hide()
                    exportToFile(response.data, this.rowExportData, 'Total Bunlde Lists', 'CSV');

                },err => {
                    console.log("erer",err)
                })
            },

            trigger(type, row){
                this.$emit('response-event', {
                    "data":row,
                    "type":type
                    // "export":type
                });
            },
            triggerTabs(type) {
                this.$emit('tabResponse-event', {
                    "type": type
                })
            },
            directPage(limit, offset) {
                this.direct_page = offset.replace(/\D/g, '');
                if (offset == 1 || offset == '') this.page_value = 1;
                else this.page_value = Math.max((offset * 10) - 9, 1);
                if (this.page_value <= this.rowTotal) { 
                    this.getAll(limit, this.page_value)
                }
            },
            showUpdateMobile(){
                this.rowHeader.map(item => {
                    if(item.key == 'edit') this.updateFlagMobile = true;
                    if(item.key == 'delete') this.deleteFlagMobile = true;
                    if(item.key == 'image') this.imageFlagMobile = true;
                    if(item.key == 'image_url') this.imageFlagMobile = true;
                    if (item.key == 'view_inventory') this.viewInventoryFlagMobile = true;
                });
            },
            pagination(type){
                if(type == 'previous'){
                    if(this.page_value > 1) this.page_value--;
                    else return this.page_value = 1
                }else {
                    if(this.noEntriesFlag) return;
                    else this.page_value++;
                }
                this.getAll(10, (this.page_value*10)-9)
            },
            directPagination(value){
                if(value > 0) {
                    this.getAll(10, (value*10)-9);
                }
                else {
                    this.getAll(10, 0);
                }
            },
            deleteCall(row){
                this.deleteData = row[this.templateValue.delete_id];
                this.showDeleteModal = true;
                this.deleteMsg = `Are you sure to delete this ${row[this.templateValue.header]} ?`
            },
            handleResponse(response) {
                if(response.code == 200){
                    this.showDeleteModal = false;
                    this.rowBody = [];
                    const loader = this.$loading.show({ width: 40, height: 40 });
                    setTimeout(() => {
                        this.getAll(10,0);
                        loader.hide();
                    }, 1000);
                }else if(response.code == 404) this.showDeleteModal = false;
                else this.showDeleteModal = true;
            },
            async getOrg(){
                const response = await this.$get('org/org_data', this.$loading, this.$toast);
                this.organizationLists = response.data;
            },
            async getAllProviders(){
                const response = await this.$get('provider/', this.$loading, this.$toast);
                this.providerList = response.data;
            },
            checkboxFilter() {
                if(this.rowBody.some(item=> item.checkbox_value && item.checkbox_value===true)){
                    this.operation_flag = false;
                } else {
                    this.select_type = 'Select All';
                    this.operation_type = '';
                    this.operation_flag = true;
                }
            },
            selectDeselect(){
                if(this.rowBody && this.rowBody.length>0){
                    const select_flag = (this.select_type == 'Select All')? true: false;
                    this.rowBody = this.rowBody.map(item=> {
                        if((this.inventory_org_filter) || (item.status == 'Fresh' && !item.org_id)){
                            item.checkbox_value = select_flag;
                        }
                        return item;
                    })
                    if(this.select_type == 'Select All'){
                        this.select_type = 'Deselect All';
                    } else{
                        this.select_type = 'Select All';
                    }
                }
            },
            triggerOperations(type){
                const data = this.rowBody.filter(item=> item.checkbox_value && item.checkbox_value===true);
                if(data.length>0){
                    this.trigger(type,data);
                }
            },
            getPage(){
                if(this.search_term){
                    var num = (this.page_value == 0) ? this.page_value + 1 : this.page_value;
                    searchSplit(this.search_term, this.rowApi, null, 10, this.page_value).then(response => {
                    this.rowBody = response.data.map(item => {
                        item.id = num++ +'.';
                        return item;
                    });
                    this.rowTotal = response.count;
                });
                }else{
                    this.getAll(10, this.page_value)
                }
            },
            copyToClipboard(value, type) {
                if(type == 'public') window.open(value, '_blank');
                else {
                    const tempInput = document.createElement('input');
                    tempInput.value = value;
                    document.body.appendChild(tempInput);
                    tempInput.select();
                    document.execCommand('copy');
                    document.body.removeChild(tempInput);
                    this.$toast.info('Your text has been copied to the clipboard.');
                }
            }
        }
    }
</script>

<style>  
    .mobileOverFlowGridM {
        width: 100%;
        background-color: #e6e6e6;
        padding: 0 1rem;
        background: linear-gradient(to bottom, #e6e6e6, #ffffff);
        border-radius: 6px; margin-top: 12px;
    } 

    .inventory-operation {
        padding: 8px;
        font-size: 15px;
        cursor: pointer;
        display: block;
    }

    .inventory-operation:hover {
        font-weight: 600;
        color: black;
    }

    .file-download {
        font-size: 14px;
        cursor: pointer;
        display: block;
    }

    .file-download:hover {
        font-size: 14px;
        font-weight: 600;
        color: black;
    }

    .dropdown_option {
        width: 168px;
        text-align: center;
        white-space: nowrap;
        font-size: 14px;
    }

    .iconbutton {
        background-color: inherit;
        color: black;
        border: none;
    }

    .iconbutton:hover {
        font-weight: 600;
        cursor: pointer;
    }

    .va-popover__content {
        background-color: white !important;
    }

    .green-badge {
        background-color: green;
        color: white;
        padding: 0 8px;
        border-radius: 50%;
    }

    .red-badge {
        background-color: red;
        color: white;
        padding: 0 8px;
        border-radius: 50%;
    }
</style>
